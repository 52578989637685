import { Box, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';

import csSvg from '../public/ca.svg';
import { useTranslation } from 'next-i18next';

const caUrl = process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL || '#';

export const PoweredByCa = () => {
  const { t } = useTranslation(['common']);

  return (
    <Link href={caUrl} target="_blank" style={{ textDecoration: 'none' }}>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        <Typography variant="subtitle1" color="GrayText">
          {t('common:poweredBy')}
        </Typography>
        <Image src={csSvg} alt="ConnectAmericas" width={100} height={50} />
      </Box>
    </Link>
  );
};
