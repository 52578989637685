import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton, IconButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';

const CloseButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

export const WhatIsCa = () => {
  const { t } = useTranslation(['common']);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="text" color="secondary" onClick={handleClickOpen}>
        {t('common:aboutCa:question')}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogContent>
          <CloseButton onClick={handleClose}>
            <Close />
          </CloseButton>
          <Typography variant="h4" gutterBottom>
            {t('common:aboutCa:ca:title')}
          </Typography>
          <Typography gutterBottom>{t('common:aboutCa:ca:body')}</Typography>
          <Typography variant="h5" gutterBottom>
            {t('common:aboutCa:idb:title')}
          </Typography>
          <Typography>{t('common:aboutCa:idb:body')}</Typography>
        </DialogContent>
        <DialogActions>
          <Link
            href={process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL}
            target="_blank"
            passHref
          >
            <Button variant="contained">
              {t('common:aboutCa:visitAction')} ConnectAmericas
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
};
