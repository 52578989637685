import { Box, Button, Container, Stack, Typography } from '@mui/material';

import { useTranslation } from 'next-i18next';
import { FeatureCard } from './FeatureCard';
import Link from 'next/link';
import { routes } from '../../../../config/routes';
import { useRouter } from 'next/router';

export const Features = () => {
  const { t } = useTranslation(['index']);
  const router = useRouter();

  return (
    <Box
      display="flex"
      gap={4}
      alignContent="center"
      flexDirection="column"
      py={8}
      px={2}
    >
      <Typography variant="h4" textAlign="center" color="textSecondary">
        {t('index:features:title')}
      </Typography>
      <Container maxWidth="lg">
        <Stack spacing={4}>
          <FeatureCard
            imageSrc="/directory.svg"
            name={t('index:features:directory:title')}
            description={t('index:features:directory:description')}
            ActionButton={() => (
              <Link href={routes.DIRECTORY}>
                <Button>{t('index:features:directory:action')}</Button>
              </Link>
            )}
          />
          <FeatureCard
            imageSrc="/announcements.svg"
            name={t('index:features:announcements:title')}
            description={t('index:features:announcements:description')}
            ActionButton={() => (
              <Link href={routes.PURCHASE_ANNOUNCEMENTS}>
                <Button>{t('index:features:announcements:action')}</Button>
              </Link>
            )}
          />
          <FeatureCard
            imageSrc="/knowledge.svg"
            name={t('index:features:knowledgeCenter:title')}
            description={t('index:features:knowledgeCenter:description')}
            ActionButton={() => (
              <Link href={routes.ARTICLES(router.locale)}>
                <Button>{t('index:features:knowledgeCenter:action')}</Button>
              </Link>
            )}
          />
          <FeatureCard
            imageSrc="/events.svg"
            name={t('index:features:events:title')}
            description={t('index:features:events:description')}
            ActionButton={() => (
              <Link href={routes.EVENTS}>
                <Button>{t('index:features:events:action')}</Button>
              </Link>
            )}
          />
        </Stack>
      </Container>
    </Box>
  );
};
