import {
  Alert,
  AlertTitle,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useUserCompanies } from '../services/hooks/useUserCompanies';
import Link from 'next/link';
import { useLocalStorage } from 'usehooks-ts';

import { routes } from '../config/routes';
import { useAuth } from '../context/Auth';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

const buildLocalStorageKey = (userId: string) => {
  return `userDismissedNoProfileAlert.${userId}`;
};

const NoProfileCreatedAlert = ({ onDismiss }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Alert
      severity="warning"
      sx={{ mt: 2 }}
      action={
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Button variant="outlined" color="error" onClick={onDismiss}>
            {t('common:noProfileAlert:dismiss')}
          </Button>
        </Box>
      }
    >
      <AlertTitle>{t('common:noProfileAlert:title')}</AlertTitle>
      {t('common:noProfileAlert:now')}{' '}
      <Link
        href={routes.MY_COMPANIES}
        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
      >
        {t('common:noProfileAlert:createProfileAction')}
      </Link>{' '}
      {t('common:noProfileAlert:body')}{' '}
      <Link
        href={routes.JOIN_A_COMPANY}
        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
      >
        {t('common:noProfileAlert:joinCompanyAction')}
      </Link>{' '}
      .
    </Alert>
  );
};

export const NoProfileCreatedAlertContainer = () => {
  const { isAuthenticated, user } = useAuth();
  const [userDismissedNoProfileAlert, setUserDismissedNoProfileAlert] =
    useState(undefined);
  const [_, saveUserDismissedNoProfileAlert] = useLocalStorage(
    buildLocalStorageKey(user?.id),
    false
  );
  const { data: userCompaniesRes } = useUserCompanies();

  const userCompanies = userCompaniesRes?.data || [];

  const fetchLocalSorageValue = useCallback((user) => {
    if (window && user) {
      const val = localStorage.getItem(buildLocalStorageKey(user.id));
      setUserDismissedNoProfileAlert(Boolean(val));
    }
  }, []);

  const onDismiss = () => {
    saveUserDismissedNoProfileAlert(true);
    fetchLocalSorageValue(user);
  };

  useEffect(() => {
    fetchLocalSorageValue(user);
  }, [user, fetchLocalSorageValue]);

  if (
    !isAuthenticated ||
    !userCompaniesRes ||
    userCompanies.length > 0 ||
    userDismissedNoProfileAlert === true
  )
    return null;

  return <NoProfileCreatedAlert onDismiss={onDismiss} />;
};
