import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import React, { FC } from 'react';

interface Props {
  name: string;
  description: string;
  ActionButton: React.ComponentType<any>;
  imageSrc: string;
}

export const FeatureCard: FC<Props> = ({
  name,
  description,
  ActionButton,
  imageSrc,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Paper
      sx={{
        padding: isMd ? 4 : 2,
        height: '100%',
        display: 'flex',
        gap: 2,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Box position="relative" width="100%" height="200px">
            <Image
              src={imageSrc}
              alt="hero"
              fill
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Stack spacing={2} alignItems="flex-start">
            <Typography variant="h4">{name}</Typography>
            <Typography variant="h5" color="textSecondary">
              {description}
            </Typography>
            <ActionButton />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
