import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { routes } from '../../../config/routes';
import { GiSewingMachine } from 'react-icons/gi';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';
export const MainCard = () => {
  const { t } = useTranslation(['index']);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Paper
      sx={{
        padding: mdUp ? 4 : 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box display="flex" flexDirection="row" gap={mdUp ? 4 : 0}>
        <Box>{mdUp ? <GiSewingMachine fontSize={100} /> : null}</Box>
        <Box flex={1}>
          <Stack spacing={2}>
            <Typography variant="h5">{t('index:cards:main:title')}</Typography>
            {!mdUp ? (
              <GiSewingMachine fontSize={90} style={{ alignSelf: 'center' }} />
            ) : null}
            <Typography variant="body1">
              {t('index:cards:main:text')}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          sx={{ alignSelf: 'center' }}
          variant="contained"
          LinkComponent={Link}
          href={routes.DIRECTORY}
        >
          {t('index:cards:main:action')}
        </Button>
      </Box>
    </Paper>
  );
};
