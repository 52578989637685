import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { routes } from '../../../config/routes';
import Link from 'next/link';
import Image from 'next/image';
import { useAuth } from '../../../context/Auth';
import { NoProfileCreatedAlertContainer } from '../../NoProfileCreatedAlertContainer';
import { useTranslation } from 'next-i18next';

export const HeroSection = () => {
  const { t } = useTranslation(['index']);
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="lg">
      <NoProfileCreatedAlertContainer />

      <Box sx={{ display: 'flex', pt: 8, pb: 0, minHeight: 450 }}>
        <Stack
          maxWidth={isMdUp ? 500 : 'none'}
          alignItems="flex-start"
          alignSelf="center"
          spacing={isMdUp ? 4 : 2}
        >
          <Typography variant="h3" component="h2" color="textPrimary">
            {t('index:hero:title')}
          </Typography>
          <Typography variant="body1" component="h3" color="textSecondary">
            {t('index:hero:subtitle')}
          </Typography>
          {isMdUp ? null : (
            <Box position="relative" width="100%" height="200px">
              <Image
                src="/hero.svg"
                alt="hero"
                fill
                style={{ objectFit: 'contain' }}
              />
            </Box>
          )}

          {!isAuthenticated ? (
            <Button
              variant="contained"
              LinkComponent={Link}
              href={routes.SIGN_UP()}
              sx={{ alignSelf: isMdUp ? 'flex-start' : 'center' }}
            >
              {t('index:hero:signUp')}
            </Button>
          ) : null}
        </Stack>
        {isMdUp ? (
          <Box flex={1} position="relative">
            <Image
              src="/hero.svg"
              alt="hero"
              fill
              style={{ objectFit: 'contain' }}
            />
          </Box>
        ) : null}
      </Box>
    </Container>
  );
};
