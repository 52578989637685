import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/system';
import { FC } from 'react';

const Wave = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  overflow: 'hidden',
  lineHeight: 0,
  '& svg': {
    position: 'relative',
    display: 'block',
    width: 'calc(120% + 1.3px)',
    height: '120px',
  },
  '& .shape-fill': {
    fill: '#FFFFFF',
  },
}));

interface WaveLayoutProps {
  spacingTop?: number;
  children: React.ReactNode;
}

export const WaveLayout: FC<WaveLayoutProps> = ({ spacingTop, children }) => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        background:
          'linear-gradient(rgba(226, 231, 251, 1), rgba(226, 231, 251, 0))',
      }}
    >
      <Wave>
        <Box
          width="100%"
          height={spacingTop ? spacingTop : '20px'}
          sx={{ background: 'white' }}
        />
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </Wave>
      <Box zIndex={10} flex={1}>
        {children}
      </Box>
    </Box>
  );
};
