import { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Link from 'next/link';
import { Box, Button } from '@mui/material';

import { routes } from '../../../config/routes';
import { useTranslation } from 'next-i18next';

export default function SignUpInput() {
  const { t } = useTranslation(['index']);
  const [email, setEmail] = useState('');

  return (
    <Box border="1px solid #9E9E9E" borderRadius="30px">
      <InputBase
        onChange={(event) => setEmail(event.target.value)}
        sx={{ px: 1, ml: 1, flex: 1, minWidth: '300px' }}
        placeholder={t('index:signUpNow:placeholder')}
      />
      <Link href={routes.SIGN_UP(email)} passHref>
        <Button size="large">{t('index:signUpNow:action')}</Button>
      </Link>
    </Box>
  );
}
