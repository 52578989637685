import { Box, useMediaQuery, useTheme } from '@mui/material';
import { PoweredByCa } from '../../PoweredByCa';
import { WhatIsCa } from './WhatIsCa';

export const AboutCaSection = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      display="flex"
      gap={4}
      justifyContent="center"
      bgcolor="rgba(244,244,244, 0.39)"
      py={2}
      flexDirection={matches ? 'row' : 'column'}
    >
      <PoweredByCa></PoweredByCa>
      <WhatIsCa></WhatIsCa>
    </Box>
  );
};
